<template>
  <ul class="qualification-progress__list">
    <li
      class="qualification-progress__item progress-item"
      :class="{ 'progress-item--done': qualification.pv >= 50 }"
    >
      <p class="progress-item__title">PV</p>
      <div class="progress-item__info">{{ qualification.pv }}/50</div>
    </li>

    <li
      class="qualification-progress__item progress-item"
      :class="{
        'progress-item--done': qualification.count_qualification_left >= 1,
      }"
    >
      <p class="progress-item__title">Лично приглашенные L</p>
      <div class="progress-item__info">
        {{ qualification.count_qualification_left }}/1
      </div>
    </li>

    <li
      class="qualification-progress__item progress-item"
      :class="{
        'progress-item--done': qualification.count_qualification_right >= 1,
      }"
    >
      <p class="progress-item__title">Лично приглашенные R</p>
      <div class="progress-item__info">
        {{ qualification.count_qualification_right }}/1
      </div>
    </li>
  </ul>
</template>

<script>
import formatQualification from "@/package/helpers/format-qualification.helpers";

export default {
  props: {
    qualification: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formatQualificationName(code) {
      return formatQualification(code) ? formatQualification(code) : "-";
    },
  },
};
</script>

<style lang="scss" scoped></style>
