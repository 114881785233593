<script>
import { mapState } from "vuex";
import QualificationProgressStart from "@/components/qualifications/QualificationProgressStart";
import QualificationProgressExpert from "@/components/qualifications/QualificationProgressExpert";
import QualificationProgressStar from "@/components/qualifications/QualificationProgressStar";
import QualificationProgressList from "@/components/qualifications/QualificationProgressList";

export default {
  components: {
    QualificationProgressList,
    QualificationProgressStart,
    QualificationProgressExpert,
    QualificationProgressStar,
  },

  props: {
    qualification: {
      type: Object,
      required: true,
    },

    invitedTspCount: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState({
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    selectedQualification() {
      return this.selectedBinaryAccount.qualification
        ? this.selectedBinaryAccount.qualification.code
        : null;
    },
  },
};
</script>

<template>
  <div class="qualification">
    <div class="qualification__progress qualification-progress">
      <p class="qualification-progress__title">До следующей квалификации:</p>

      <qualification-progress-start
        v-if="selectedQualification === null"
        :qualification="qualification"
      />

      <qualification-progress-expert
        v-if="selectedQualification === 'start'"
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :child-qualifications="[
          'start',
          'expert',
          'star',
          'double_star',
          'diamond',
          'double_diamond',
          'grand_diamond',
          'diamond_elite',
          'ambassador',
          'v_president',
        ]"
      />

      <qualification-progress-star
        v-if="selectedQualification === 'expert'"
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :child-qualifications="[
          'start',
          'expert',
          'star',
          'double_star',
          'diamond',
          'double_diamond',
          'grand_diamond',
          'diamond_elite',
          'ambassador',
          'v_president',
        ]"
      />

      <qualification-progress-list
        v-if="selectedQualification === 'star'"
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :cycles="20"
        :pv="8000"
        :child-qualifications="[
          'expert',
          'star',
          'double_star',
          'diamond',
          'double_diamond',
          'grand_diamond',
          'diamond_elite',
          'ambassador',
          'v_president',
        ]"
      />

      <qualification-progress-list
        v-if="selectedQualification === 'double_star'"
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :cycles="40"
        :pv="20000"
        :child-qualifications="[
          'star',
          'double_star',
          'diamond',
          'double_diamond',
          'grand_diamond',
          'diamond_elite',
          'ambassador',
          'v_president',
        ]"
      />

      <qualification-progress-list
        v-if="selectedQualification === 'diamond'"
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :cycles="80"
        :pv="40000"
        :child-qualifications="[
          'double_star',
          'diamond',
          'double_diamond',
          'grand_diamond',
          'diamond_elite',
          'ambassador',
          'v_president',
        ]"
      />

      <qualification-progress-list
        v-if="selectedQualification === 'double_diamond'"
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :cycles="120"
        :pv="100000"
        :child-qualifications="[
          'diamond',
          'double_diamond',
          'grand_diamond',
          'diamond_elite',
          'ambassador',
          'v_president',
        ]"
      />

      <qualification-progress-list
        v-if="selectedQualification === 'grand_diamond'"
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :cycles="200"
        :pv="160000"
        :child-qualifications="[
          'double_diamond',
          'grand_diamond',
          'diamond_elite',
          'ambassador',
          'v_president',
        ]"
      />

      <qualification-progress-list
        v-if="selectedQualification === 'diamond_elite'"
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :cycles="350"
        :pv="300000"
        :child-qualifications="[
          'grand_diamond',
          'diamond_elite',
          'ambassador',
          'v_president',
        ]"
      />

      <qualification-progress-list
        v-if="
          selectedQualification === 'ambassador' ||
          selectedQualification === 'v_president'
        "
        :qualification="qualification"
        :invited-tsp-count="invitedTspCount"
        :cycles="600"
        :pv="500000"
        :child-qualifications="['diamond_elite', 'ambassador', 'v_president']"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.qualification {
  background-color: transparent;
  width: 100%;

  .qualification-progress {
    &__title {
      margin-bottom: $space-m;
      margin-left: 0;
      @include body-1-bold;
      color: $dark-primary;
    }
  }
}
</style>
