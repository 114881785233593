var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qualification-progress__wrapper"},[_c('ul',{staticClass:"qualification-progress__list"},[_c('li',{staticClass:"qualification-progress__item progress-item",class:{
        'progress-item--done':
          _vm.qualification.fin_cycles.previous_week >= _vm.cycles,
      }},[_c('p',{staticClass:"progress-item__title"},[_vm._v("Циклов за прошлую неделю")]),_c('div',{staticClass:"progress-item__info"},[_vm._v(" "+_vm._s(_vm.qualification.fin_cycles.previous_week)+"/"+_vm._s(_vm.cycles)+" ")])]),_c('li',{staticClass:"qualification-progress__item progress-item",class:{
        'progress-item--done':
          _vm.qualification.fin_cycles.current_week >= _vm.cycles,
      }},[_c('p',{staticClass:"progress-item__title"},[_vm._v("Циклов за текущую неделю")]),_c('div',{staticClass:"progress-item__info"},[_vm._v(" "+_vm._s(_vm.qualification.fin_cycles.current_week)+"/"+_vm._s(_vm.cycles)+" ")])])]),_c('button-redirect',{attrs:{"text":"История циклов","arrow-position":"right","button-style":"background"},on:{"click":function($event){return _vm.$router.push({ name: 'FinancialCycles' })}}}),_c('ul',{staticClass:"qualification-progress__list"},[_c('li',{staticClass:"qualification-progress__item progress-item",class:{ 'progress-item--done': _vm.qualification.pv >= _vm.pv }},[_c('p',{staticClass:"progress-item__title"},[_vm._v("PV")]),_c('div',{staticClass:"progress-item__info"},[_vm._v(_vm._s(_vm.qualification.pv)+"/"+_vm._s(_vm.pv))])]),_c('li',{staticClass:"qualification-progress__item progress-item",class:{
        'progress-item--done': _vm.childQualifications.includes(
          _vm.qualification.max_qualification_left
        ),
      }},[_c('p',{staticClass:"progress-item__title"},[_vm._v("Квалификация L")]),_c('div',{staticClass:"progress-item__info"},[_vm._v(" от "+_vm._s(_vm.currentRequiredQualificationName)+" ")])]),_c('li',{staticClass:"qualification-progress__item progress-item",class:{
        'progress-item--done': _vm.childQualifications.includes(
          _vm.qualification.max_qualification_right
        ),
      }},[_c('p',{staticClass:"progress-item__title"},[_vm._v("Квалификация R")]),_c('div',{staticClass:"progress-item__info info"},[_vm._v(" от "+_vm._s(_vm.currentRequiredQualificationName)+" ")])]),_c('li',{staticClass:"qualification-progress__item progress-item",class:{
        'progress-item--done': _vm.invitedTspCount >= 1,
      }},[_c('p',{staticClass:"progress-item__title"},[_vm._v("Подключенных ТСП")]),_c('div',{staticClass:"progress-item__info"},[_vm._v(_vm._s(_vm.invitedTspCount)+"/1")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }