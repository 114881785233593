var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qualification"},[_c('div',{staticClass:"qualification__progress qualification-progress"},[_c('p',{staticClass:"qualification-progress__title"},[_vm._v("До следующей квалификации:")]),(_vm.selectedQualification === null)?_c('qualification-progress-start',{attrs:{"qualification":_vm.qualification}}):_vm._e(),(_vm.selectedQualification === 'start')?_c('qualification-progress-expert',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"child-qualifications":[
        'start',
        'expert',
        'star',
        'double_star',
        'diamond',
        'double_diamond',
        'grand_diamond',
        'diamond_elite',
        'ambassador',
        'v_president',
      ]}}):_vm._e(),(_vm.selectedQualification === 'expert')?_c('qualification-progress-star',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"child-qualifications":[
        'start',
        'expert',
        'star',
        'double_star',
        'diamond',
        'double_diamond',
        'grand_diamond',
        'diamond_elite',
        'ambassador',
        'v_president',
      ]}}):_vm._e(),(_vm.selectedQualification === 'star')?_c('qualification-progress-list',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"cycles":20,"pv":8000,"child-qualifications":[
        'expert',
        'star',
        'double_star',
        'diamond',
        'double_diamond',
        'grand_diamond',
        'diamond_elite',
        'ambassador',
        'v_president',
      ]}}):_vm._e(),(_vm.selectedQualification === 'double_star')?_c('qualification-progress-list',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"cycles":40,"pv":20000,"child-qualifications":[
        'star',
        'double_star',
        'diamond',
        'double_diamond',
        'grand_diamond',
        'diamond_elite',
        'ambassador',
        'v_president',
      ]}}):_vm._e(),(_vm.selectedQualification === 'diamond')?_c('qualification-progress-list',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"cycles":80,"pv":40000,"child-qualifications":[
        'double_star',
        'diamond',
        'double_diamond',
        'grand_diamond',
        'diamond_elite',
        'ambassador',
        'v_president',
      ]}}):_vm._e(),(_vm.selectedQualification === 'double_diamond')?_c('qualification-progress-list',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"cycles":120,"pv":100000,"child-qualifications":[
        'diamond',
        'double_diamond',
        'grand_diamond',
        'diamond_elite',
        'ambassador',
        'v_president',
      ]}}):_vm._e(),(_vm.selectedQualification === 'grand_diamond')?_c('qualification-progress-list',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"cycles":200,"pv":160000,"child-qualifications":[
        'double_diamond',
        'grand_diamond',
        'diamond_elite',
        'ambassador',
        'v_president',
      ]}}):_vm._e(),(_vm.selectedQualification === 'diamond_elite')?_c('qualification-progress-list',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"cycles":350,"pv":300000,"child-qualifications":[
        'grand_diamond',
        'diamond_elite',
        'ambassador',
        'v_president',
      ]}}):_vm._e(),(
        _vm.selectedQualification === 'ambassador' ||
        _vm.selectedQualification === 'v_president'
      )?_c('qualification-progress-list',{attrs:{"qualification":_vm.qualification,"invited-tsp-count":_vm.invitedTspCount,"cycles":600,"pv":500000,"child-qualifications":['diamond_elite', 'ambassador', 'v_president']}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }