<script>
import formatQualification from "@/package/helpers/format-qualification.helpers";
import ButtonRedirect from "@/components/helpers/ButtonRedirect";

export default {
  components: {
    ButtonRedirect,
  },

  props: {
    qualification: {
      type: Object,
      required: true,
    },

    invitedTspCount: {
      type: Number,
      required: true,
    },

    childQualifications: {
      type: Array,
      required: true,
    },
  },

  methods: {
    formatQualificationName(code) {
      return formatQualification(code) ? formatQualification(code) : "-";
    },
  },
};
</script>

<template>
  <div class="qualification-progress__wrapper">
    <ul class="qualification-progress__list">
      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': qualification.fin_cycles.previous_week >= 6,
        }"
      >
        <p class="progress-item__title">Циклов за прошлую неделю</p>
        <div class="progress-item__info">
          {{ qualification.fin_cycles.previous_week }}/6
        </div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': qualification.fin_cycles.current_week >= 6,
        }"
      >
        <p class="progress-item__title">Циклов за текущую неделю</p>
        <div class="progress-item__info">
          {{ qualification.fin_cycles.current_week }}/6
        </div>
      </li>
    </ul>

    <button-redirect
      text="История циклов"
      arrow-position="right"
      button-style="background"
      @click="$router.push({ name: 'FinancialCycles' })"
    />

    <ul class="qualification-progress__list">
      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': childQualifications.includes(
            qualification.max_qualification_left
          ),
        }"
      >
        <p class="progress-item__title">Квалификация L</p>
        <div class="progress-item__info">от Старт</div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': childQualifications.includes(
            qualification.max_qualification_right
          ),
        }"
      >
        <p class="progress-item__title">Квалификация R</p>
        <div class="progress-item__info info">от Старт</div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': qualification.count_qualification_left >= 1,
        }"
      >
        <p class="progress-item__title">Лично приглашенные L</p>
        <div class="progress-item__info">
          {{ qualification.count_qualification_left }}/1
        </div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': qualification.count_qualification_right >= 1,
        }"
      >
        <p class="progress-item__title">Лично приглашенные R</p>
        <div class="progress-item__info">
          {{ qualification.count_qualification_right }}/1
        </div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{ 'progress-item--done': qualification.pv >= 1600 }"
      >
        <p class="progress-item__title">PV</p>
        <div class="progress-item__info">{{ qualification.pv }}/1600</div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': invitedTspCount >= 1,
        }"
      >
        <p class="progress-item__title">Подключенных ТСП</p>
        <div class="progress-item__info">{{ invitedTspCount }}/1</div>
      </li>
    </ul>
  </div>
</template>
