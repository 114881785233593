<script>
import formatQualification from "@/package/helpers/format-qualification.helpers";
import ButtonRedirect from "@/components/helpers/ButtonRedirect";

export default {
  components: { ButtonRedirect },

  props: {
    qualification: {
      type: Object,
      required: true,
    },

    invitedTspCount: {
      type: Number,
      required: true,
    },

    childQualifications: {
      type: Array,
      required: true,
    },
  },

  computed: {
    currentRequiredQualificationName() {
      return formatQualification(this.childQualifications[0]);
    },
  },

  methods: {
    formatQualificationName(code) {
      return formatQualification(code) ? formatQualification(code) : "-";
    },
  },
};
</script>

<template>
  <div class="qualification-progress__wrapper">
    <ul class="qualification-progress__list">
      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': qualification.fin_cycles.previous_week >= 3,
        }"
      >
        <p class="progress-item__title">Циклов за прошлую неделю</p>
        <div class="progress-item__info">
          {{ qualification.fin_cycles.previous_week }}/3
        </div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': qualification.fin_cycles.current_week >= 3,
        }"
      >
        <p class="progress-item__title">Циклов за текущую неделю</p>
        <div class="progress-item__info">
          {{ qualification.fin_cycles.current_week }}/3
        </div>
      </li>
    </ul>

    <button-redirect
      text="История циклов"
      arrow-position="right"
      button-style="background"
      @click="$router.push({ name: 'FinancialCycles' })"
    />

    <ul class="qualification-progress__list">
      <li
        class="qualification-progress__item progress-item"
        :class="{ 'progress-item--done': qualification.pv >= 800 }"
      >
        <p class="progress-item__title">PV</p>
        <div class="progress-item__info">{{ qualification.pv }}/800</div>
      </li>

      <li
        v-if="
          !(
            qualification.count_qualification_right !== 0 &&
            qualification.count_qualification_left === 0
          )
        "
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': qualification.count_qualification_left >= 1,
        }"
      >
        <p class="progress-item__title">Лично приглашенные L</p>
        <div class="progress-item__info">
          {{ qualification.count_qualification_left }}/1
        </div>
      </li>

      <li
        v-if="
          !(
            qualification.count_qualification_left !== 0 &&
            qualification.count_qualification_right === 0
          )
        "
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': qualification.count_qualification_right >= 1,
        }"
      >
        <p class="progress-item__title">Лично приглашенные R</p>
        <div class="progress-item__info">
          {{ qualification.count_qualification_right }}/1
        </div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done': invitedTspCount >= 1,
        }"
      >
        <p class="progress-item__title">Подключенных ТСП</p>
        <div class="progress-item__info">{{ invitedTspCount }}/1</div>
      </li>

      <li
        class="qualification-progress__item progress-item"
        :class="{
          'progress-item--done':
            childQualifications.includes(
              qualification.max_qualification_left
            ) ||
            childQualifications.includes(qualification.max_qualification_right),
        }"
      >
        <p class="progress-item__title">Квалификация от Старт</p>
        <div class="progress-item__info">1</div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.qualification-progress__wrapper {
  .qualification-progress__list {
    &:first-child {
      margin-bottom: 8px;
    }
  }

  .button-redirect {
    margin-bottom: 8px;
  }
}
</style>
